<template>
    <div class="flex flex-column py-8">
        <div class="flex flex-column align-items-center justify-content-center flex-grow-1">
            <div class="text-center mb-5 w-11 lg:w-6">
                <img src="/images/logo.png" alt="Comunidae logo" height="75" class="mb-3">
                <div class="text-900 text-3xl font-medium mb-3">{{ $appState.estate?.name }}</div>
            </div>
            <div class="w-11 lg:w-6 page-content">
                <h3 class="text-center mb-5">{{ page?.name }}</h3>
                <div class="w-full" v-if="loading">
                    <Skeleton shape="rectangle" width="100%" height="1.4rem" class="mb-3" v-for="n in 8" :key="n" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageService from '@/service/PageService.js';
import ErrorReportService from '@/service/ErrorReportService.js';

export default {
    data() {
        return {
            page: null,
            loading: true,
        }
    },
    pageService: null,
    errorReportService: null,
    pageContent: null,
    pageSlug: null,
    created() {
        this.pageService = new PageService();
        this.errorReportService = new ErrorReportService();

        if (!this.$route.params.slug) {
            this.$watch(
                () => this.$route.params,
                (params) => {
                    this.pageSlug = params.slug;
                },
            )
        } else {
            this.pageSlug = this.$route.params.slug;
        }
    },
    mounted() {
        this.pageContent = document.querySelector('.page-content');

        if (!this.$appState.estate) {
            this.$watch(
                () => this.$appState.estate,
                () => {
                    this.fetchPage();
                },
            );
        } else {
            this.fetchPage();
        }
    },
    methods: {
        fetchPage() {
            this.loading = true;

            this.pageService.fetchFooterPageContent(this.$appState.estate.id, this.pageSlug).then(response => {
                this.page = response.data.page;

                if (this.page.content.page_type === 'basic') {
                    this.pageService.insertBasicContent(this.page.content.layout[0].content, this.pageContent);
                } else if (this.page.content.page_type === 'custom') {
                    this.pageService.createLayout(this.page.content.layout, this.pageContent, null, response.data.page.page_links, response.data.page.category_links);
                }

                this.loading = false;
            }).catch(error => {
                console.error(error);
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido obtener la página', life: 3000});
            });
        },
    },
}
</script>
<style scoped>
</style>